import React, { ReactElement } from 'react'

import { type BoxProps } from '@mui/material'

import { ReactComponent as LogoIcon } from './assets/logo.svg'
import { IconBox } from './styles'

interface TauroSpinnerProps extends Omit<BoxProps, 'ref'> {}

export function TauroSpinner(props: TauroSpinnerProps): ReactElement {
  const { ...rest } = props

  return <IconBox component={LogoIcon} {...rest} />
}
