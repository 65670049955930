import React, { lazy } from 'react'
import { Route } from 'react-router-dom'

import { websiteRoutes } from '../../shared/constants'
import BaseFaqLayout from './components/BaseFaqLayout'

const AboutTrading = lazy(() => import('./AboutTrading'))
const DepositsWithdrawals = lazy(() => import('./DepositsWithdrawals'))
const FaqMain = lazy(() => import('./FaqMain'))
const GeneralAccountQuestion = lazy(() => import('./GeneralAccountQuestion'))
const PartnershipPrograms = lazy(() => import('./PartnershipPrograms'))
const TradingPlatform = lazy(() => import('./TradingPlatform'))
const Troubleshooting = lazy(() => import('./Troubleshooting'))

export const ROUTE = (
  <Route element={<BaseFaqLayout />} path={websiteRoutes.faq}>
    <Route element={<FaqMain />} index />
    <Route element={<AboutTrading />} path={websiteRoutes.aboutTrading} />
    <Route element={<DepositsWithdrawals />} path={websiteRoutes.depositsWithdrawals} />
    <Route element={<GeneralAccountQuestion />} path={websiteRoutes.generalAccountQuestion} />
    <Route element={<PartnershipPrograms />} path={websiteRoutes.partnershipPrograms} />
    <Route element={<TradingPlatform />} path={websiteRoutes.tradingPlatforms} />
    <Route element={<Troubleshooting />} path={websiteRoutes.troubleshooting} />
  </Route>
)
