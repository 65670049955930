import { useContext } from 'react'

import { RestClientContext, RestClientContextType } from '../context'

export const useRestClient = (): RestClientContextType => {
  const invariant = useContext(RestClientContext)

  if (!invariant) throw new Error('RestClientContext is null')

  return invariant
}
