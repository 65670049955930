import React, { ReactElement, ReactNode } from 'react'

import { Stack } from '@mui/material'

import { useBackgroundImage } from '../../shared/hooks/use-background-image'
import ContactBackgroundDesktopImage from './assets/bg_contactfooter_desktop.webp'
import ContactBackgroundMobileImage from './assets/bg_contactfooter_mobile.webp'
import { QuestionForm } from './components/QuestionForm'
import { BlockLayout } from './styles'

type QuestionFormBlockProps = {
  className?: string
  content?: ReactNode
  form?: ReactNode
}

export function QuestionFormBlock(props: QuestionFormBlockProps): ReactElement {
  const { content, form = <QuestionForm />, ...rest } = props

  const backgroundImageSrc = useBackgroundImage({
    desktop: ContactBackgroundDesktopImage,
    mobile: ContactBackgroundMobileImage,
  })

  return (
    <BlockLayout backgroundImageSrc={backgroundImageSrc} {...rest}>
      <Stack direction={{ md: 'row', xs: 'column' }} gap={2.5}>
        {content}
        {form}
      </Stack>
    </BlockLayout>
  )
}
