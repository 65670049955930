import type { ReactElement, ReactNode } from 'react'
import React from 'react'

import createCache from '@emotion/cache'
import { ThemeProvider as EmotionThemeProvider, CacheProvider } from '@emotion/react'
import { useLingui } from '@lingui/react'
import { CssBaseline } from '@mui/material'
import { prefixer } from 'stylis'
import rtlPlugin from 'stylis-plugin-rtl'

import '../../styles/fonts.css'
import { theme } from './theme'

type ThemeProviderProps = {
  children: ReactNode
}

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
})

const ltrCache = createCache({
  key: 'mui',
})
export function ThemeProvider(props: ThemeProviderProps): ReactElement {
  const { children } = props

  const {
    i18n: { locale },
  } = useLingui()

  const cache = locale === 'ar' ? cacheRtl : ltrCache

  return (
    <EmotionThemeProvider theme={theme}>
      <CacheProvider value={cache}>
        <CssBaseline />
        {children}
      </CacheProvider>
    </EmotionThemeProvider>
  )
}
