import React, { ReactElement } from 'react'

import { Stack } from '@mui/material'

import { JoinNowButton } from '../../../../../JoinNowButton'
import { LogInButton } from '../../../../../LogInButton'

export function AuthButtons(): ReactElement {
  return (
    <Stack alignItems="center" direction="row" gap={0.5}>
      <LogInButton size="small" />
      <JoinNowButton size="small" />
    </Stack>
  )
}
