import React, { FormEvent, ReactElement } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Trans } from '@lingui/macro'
import { Button, TextField } from '@mui/material'

import { getEmailFieldRules, getFullNameFieldRules, getRequiredFieldRule } from '../../../../shared/validators'
import { useSubmitForm } from '../../hooks/use-submit-form'
import { InitialQuestionForm } from './constants'
import { Paper } from './styles'
import { QuestionFormType } from './types'

type QuestionFormProps = { className?: string }

export function QuestionForm(props: QuestionFormProps): ReactElement {
  const { className } = props

  const [formSubmit] = useSubmitForm()

  const { control, handleSubmit } = useForm<QuestionFormType>({ defaultValues: InitialQuestionForm })

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>): void => void handleSubmit(formSubmit)(e)

  return (
    <form style={{ width: '100%' }} onSubmit={handleFormSubmit}>
      <Paper className={className}>
        <Controller
          render={({ field, fieldState }) => (
            <TextField
              label={<Trans>Full name</Trans>}
              type="text"
              {...field}
              error={Boolean(fieldState.error)}
              helperText={fieldState.error?.message}
              variant="filled"
              fullWidth
            />
          )}
          control={control}
          name="fullName"
          rules={getFullNameFieldRules()}
        />
        <Controller
          render={({ field, fieldState }) => (
            <TextField
              label={<Trans>Phone</Trans>}
              type="tel"
              {...field}
              error={Boolean(fieldState.error)}
              helperText={fieldState.error?.message}
              variant="filled"
              fullWidth
            />
          )}
          control={control}
          name="phone"
          rules={getRequiredFieldRule()}
        />
        <Controller
          render={({ field, fieldState }) => (
            <TextField
              label={<Trans>Email</Trans>}
              type="email"
              {...field}
              error={Boolean(fieldState.error)}
              helperText={fieldState.error?.message}
              variant="filled"
            />
          )}
          control={control}
          name="email"
          rules={getEmailFieldRules()}
        />
        <Controller
          render={({ field, fieldState }) => (
            <TextField
              label={<Trans>Country</Trans>}
              type="text"
              {...field}
              error={Boolean(fieldState.error)}
              helperText={fieldState.error?.message}
              variant="filled"
            />
          )}
          control={control}
          name="country"
        />
        <Controller
          render={({ field, fieldState }) => (
            <TextField
              label={<Trans>Message</Trans>}
              type="text"
              {...field}
              error={Boolean(fieldState.error)}
              helperText={fieldState.error?.message}
              rows={4}
              variant="filled"
              multiline
            />
          )}
          control={control}
          name="message"
          rules={getRequiredFieldRule()}
        />
        <Button sx={{ md: { width: '240px' }, sm: { width: '100%' } }} type="submit" variant="contained">
          <Trans>Submit</Trans>
        </Button>
      </Paper>
    </form>
  )
}
