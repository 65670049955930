import React, { ReactElement, ReactNode } from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Collapse, List, ListItem, Menu, Stack } from '@mui/material'

import { useIsMobile } from '../../../../../../shared/hooks/is-mobile'
import { useToggle } from '../../../../../../shared/hooks/use-toggle'
import { Button, CollapseButton, Icon, Link } from './styled'

export type NavItemProps = {
  children?: ReactNode
  className?: string
  to?: string
  title: ReactNode
  subItems?: NavItemProps[]
  canBeActive?: boolean
  onClick?: () => void
}

export function NavItem(props: NavItemProps): ReactElement {
  const { canBeActive = true, className, onClick, subItems = [], title, to = '/' } = props

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [open, toggleOpen] = useToggle(false)

  const handleClickExpand = (event: React.MouseEvent<HTMLElement>): void => {
    toggleOpen()
    setAnchorEl(event.currentTarget)
  }
  const handleClickClose = (): void => {
    toggleOpen(false)
    setAnchorEl(null)
  }

  if (subItems.length > 0)
    return (
      <Stack sx={{ overflow: 'visible', position: 'relative' }}>
        <CollapseButton disableRipple onClick={handleClickExpand}>
          {title}
          <Icon>
            <ExpandMoreIcon
              sx={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.2s ease-in-out' }}
            />
          </Icon>
        </CollapseButton>
        <SubItemsContainer anchorEl={anchorEl} open={open} onClose={handleClickClose}>
          <List>
            {subItems.map((subItem) => (
              <ListItem key={subItem.to}>
                <NavItem {...subItem} onClick={onClick} />
              </ListItem>
            ))}
          </List>
        </SubItemsContainer>
      </Stack>
    )
  return (
    <Link className={className} to={to}>
      {({ isActive }) => (
        <Button isActive={isActive && canBeActive} size="small" onClick={onClick}>
          {title}
        </Button>
      )}
    </Link>
  )
}

const SubItemsContainer = (props: {
  children: ReactNode
  open: boolean
  onClose?: () => void
  anchorEl?: HTMLElement | null
}): ReactElement | null => {
  const { anchorEl, children, onClose, open } = props
  const isMobile = useIsMobile()

  if (isMobile)
    return (
      <Collapse in={open} timeout="auto" onClick={onClose}>
        {children}
      </Collapse>
    )

  return (
    <Menu anchorEl={anchorEl} open={open} onClick={onClose} onClose={onClose}>
      {children}
    </Menu>
  )
}
