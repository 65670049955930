import React, { ReactElement } from 'react'

import { Trans } from '@lingui/macro'
import { Stack, Typography } from '@mui/material'

import { QuestionFormBlock } from '../../../../../components/QuestionFormBlock'

export function FaqQuestionFormBlock(): ReactElement {
  return (
    <QuestionFormBlock
      content={
        <Stack gap={4.25}>
          <Typography
            sx={({ palette }) => ({ '& > strong': { color: palette.tauroRed.main } })}
            textAlign="left"
            variant="h2"
          >
            <Trans>
              Can’t find what you’re looking for? <strong>Contact Us</strong>
            </Trans>
          </Typography>
          <Typography textAlign="left" variant="body1">
            <Trans>
              If you have any questions that aren&apos;t covered in our FAQ, we&apos;re here to help! Reach out to our
              support team for personalized assistance.
            </Trans>
          </Typography>
        </Stack>
      }
    />
  )
}
