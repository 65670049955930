import { Stack, styled, Typography } from '@mui/material'

export const FooterContainer = styled(Stack)({
  backgroundColor: '#ECEBEB',
  position: 'relative',
  width: '100%',
})

export const Text = styled(Typography)(({ theme }) => ({
  color: '#344054',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '21px',

  margin: theme.spacing(0, 0, 3.5),
}))

export const PaymentContainer = styled(Stack, { shouldForwardProp: (prop) => prop !== 'isMobile' })<{
  isMobile: boolean
}>(({ isMobile }) => ({
  backgroundColor: '#F7F7F7',
  padding: isMobile ? '30px 62px' : '40px 100px',
  width: '100%',
}))

export const PaymentImage = styled('img', { shouldForwardProp: (prop) => prop !== 'isMobile' })<{ isMobile: boolean }>(
  ({ isMobile }) => ({
    height: isMobile ? '23px' : '33px',
  }),
)
