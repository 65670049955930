import React, { ForwardedRef, forwardRef, ReactElement } from 'react'

import { Box, type BoxProps } from '@mui/material'

import { ReactComponent as LogoIcon } from './assets/tauromarkets_logo.svg'

interface LogoProps extends Omit<BoxProps, 'ref'> {}

function LogoBase(props: LogoProps, ref: ForwardedRef<SVGSVGElement>): ReactElement {
  const { ...rest } = props

  return <Box component={LogoIcon} ref={ref} {...rest} />
}

const Logo = forwardRef(LogoBase)

export { Logo }
