import { RegisterOptions } from 'react-hook-form'

import { t } from '@lingui/macro'

import { getRequiredFieldRule } from './required'

const MIN_FULL_NAME_LENGTH = 5

export const getFullNameFieldRules = (): Pick<RegisterOptions, 'minLength' | 'required'> => ({
  ...getRequiredFieldRule(),
  minLength: { message: t`Full name must be at least ${MIN_FULL_NAME_LENGTH} symbols`, value: MIN_FULL_NAME_LENGTH },
})
