import { usePost } from '../../../shared/hooks/use-post'
import { QuestionFormType } from '../components/QuestionForm/types'

type UseFormSubmitReturn<T> = [
  (payload: QuestionFormType) => void,
  {
    data: T | undefined
    loading: boolean
    error: unknown
  },
]

export function useSubmitForm<T = unknown>(): UseFormSubmitReturn<T> {
  const [submit, { data, error, loading }] = usePost<T>({ path: '/question' }, { showNotification: true })

  return [submit, { data, error, loading }]
}
