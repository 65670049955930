import { useCallback, useEffect, useState } from 'react'

import { useRestClient } from '../../../../../providers/RestClientProvider/hooks/use-rest-client'
import { RestClientInstance } from '../../../../../shared/create-client'
import { COUNTRIES_ALPHA2_NEED_TO_ACKNOWLEDGE_SET } from '../constants'

type UseAcknowledgeReturnedType = [boolean, string, () => void, () => void]

export function useAcknowledge(): UseAcknowledgeReturnedType {
  const [isAcknowledged, setAcknowledged] = useState(true)
  const [countryCode, setCountryCode] = useState('eu')
  const { client } = useRestClient()

  useEffect(() => {
    getConsumerCountryAlpha2(client)
      .then((countryCode) => {
        setCountryCode(countryCode)
        COUNTRIES_ALPHA2_NEED_TO_ACKNOWLEDGE_SET.has(countryCode.toLowerCase())
          ? setAcknowledged(false)
          : setAcknowledged(true)
      })
      .catch(() => {
        setAcknowledged(false)
      })
  }, [])

  const closeWindow = (): void => {
    window.open('about:blank', '_self')
  }

  const acknowledge = useCallback(() => setAcknowledged(true), [setAcknowledged])

  return [isAcknowledged, countryCode, closeWindow, acknowledge]
}

const getConsumerCountryAlpha2 = async (client: RestClientInstance): Promise<string> => {
  const { data } = await client.get<{ country_code: string }>('get-country')

  return data.country_code
}
