import { Components } from '@mui/material'

export const appBar: Pick<Components, 'MuiAppBar'> = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: '##FFFFFF',
        boxShadow: 'none',
        color: '#FFFFFF',
      },
    },
  },
}
