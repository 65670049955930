export const videoCourses = {
  advancedStockMarketTradingLevel1: 'advanced-stock-market-trading-level-1',
  advancedStockMarketTradingLevel2: 'advanced-stock-market-trading-level-2',
  advancedTradingTools: 'advanced-trading-tools',
  basicForex: 'basic-forex',
  candlesticks: 'candlesticks',
  cryptoCurrencies: 'crypto-currencies',
  fibonacci: 'fibonacci',
  forexIndicators: 'forex-indicators',
  mt4: 'mt4',
  patterns: 'patterns',
  stockMarketIntroduction: 'stock-market-introduction',
  timingInForex: 'timing-in-forex',
  topTrader: 'top-trader',
} as const
