import React, { ReactElement, ReactNode } from 'react'

import { RestClientContext } from './context'
import { useCreateClient } from './hooks/use-create-client'

type AuthProviderProps = {
  children: ReactNode
}

export function RestClientProvider({ children }: AuthProviderProps): ReactElement {
  const client = useCreateClient()

  return <RestClientContext.Provider value={{ client }}>{children}</RestClientContext.Provider>
}
