import type {} from '@mui/lab/themeAugmentation'

import { createTheme } from '@mui/material/styles'

import {
  palette,
  buttons,
  typographyOverride,
  shadows,
  accordionOverride,
  textfieldOverride,
  appBar,
  dialogOverride,
} from './overrides'

const theme = createTheme({
  components: {
    ...buttons,
    ...appBar,
  },
  palette,
  shadows,
  shape: {
    borderRadius: 8,
  },
})

theme.typography = { ...theme.typography, ...typographyOverride(theme) }
theme.components = {
  ...theme.components,
  ...accordionOverride(theme),
  ...dialogOverride(theme),
  ...textfieldOverride(theme),
}

export { theme }
