export const educationRoutes = {
  education: '/education',
  educationAdvanced: '/education/advanced',
  educationBeginner: '/education/beginner',
  educationCourses: '/education/courses',
  educationIntermediate: '/education/intermediate',
  educationTools: '/education/tools',
} as const

export const faqRoutes = {
  aboutTrading: '/faq/about-trading',
  depositsWithdrawals: '/faq/deposits-withdrawals',
  generalAccountQuestion: '/faq/general-account-questions',
  partnershipPrograms: '/faq/partnership-programs',
  tradingPlatforms: '/faq/trading-platforms',
  troubleshooting: '/faq/troubleshooting',
} as const

export const websiteRoutes = {
  about: '/about-us',
  accountTypes: '/?anchor=account-types',
  contacts: '/contact-us',
  liveChat: '/contact-us/live-chat',
  ...educationRoutes,
  faq: '/faq',
  ...faqRoutes,
  home: '/',
  legalDocuments: '/legal-documents',
  partners: '/partners',
} as const
