import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import DehazeIcon from '@mui/icons-material/Dehaze'
import { Drawer as MuiDrawer, Stack } from '@mui/material'

import { websiteRoutes } from '../../../../shared/constants'
import { useIsMobile } from '../../../../shared/hooks/is-mobile'
import { useToggle } from '../../../../shared/hooks/use-toggle'
import { Logo } from '../../../Logo'
import { AuthButtons } from './components/AuthButtons'
import { LocaleSelector } from './components/LocaleSelector'
import { Navigation } from './components/Navigation'
import { DrawerHeaderContainer, NavigationContainer, Icon } from './styled'

export function Header(): ReactElement {
  const [open, toggleOpen] = useToggle()
  const isMobile = useIsMobile()

  return (
    <>
      <NavigationContainer alignItems="center" direction="row" justifyContent="space-between">
        <Link to={websiteRoutes.home}>
          <Logo height="40px" minWidth="60px" />
        </Link>

        {isMobile ? (
          <Icon onClick={toggleOpen}>
            <DehazeIcon />
          </Icon>
        ) : (
          <>
            <Navigation />
            <Stack direction="row">
              <AuthButtons />
              <LocaleSelector />
            </Stack>
          </>
        )}
      </NavigationContainer>
      {isMobile ? <Drawer open={open} toggleOpen={toggleOpen} /> : null}
    </>
  )
}

const Drawer = ({ open, toggleOpen }: { open: boolean; toggleOpen: () => void }): ReactElement => (
  <MuiDrawer
    PaperProps={{ sx: { width: '100%' } }}
    anchor="left"
    open={open}
    sx={{ width: '100%' }}
    variant="persistent"
    onClose={toggleOpen}
  >
    <DrawerHeaderContainer alignItems="center" direction="row" justifyContent="space-between" width="100%">
      <Logo height="40px" />
      <Icon onClick={toggleOpen}>
        <CloseIcon />
      </Icon>
    </DrawerHeaderContainer>
    <Stack alignItems="center" height="100%" justifyContent="space-between" p="24px 16px 24px 16px">
      <Stack width="100%">
        <Navigation onClick={toggleOpen} />
        <LocaleSelector />
      </Stack>
      <AuthButtons />
    </Stack>
  </MuiDrawer>
)
