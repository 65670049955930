import React from 'react'

import { Trans } from '@lingui/macro'

import { Locale } from '../../../../../../shared/i18n'
import { SelectItem } from '../../../../../Select'

export const LOCALES: SelectItem<Locale>[] = [
  {
    label: <Trans>EN</Trans>,
    value: 'en',
  },
  {
    label: <Trans>AR</Trans>,
    value: 'ar',
  },
]
