import { Components } from '@mui/material'

export const buttons: Pick<Components, 'MuiButton' | 'MuiButtonBase'> = {
  MuiButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      contained: {
        ':active': {
          border: '4px solid #FFE1E1',
          boxShadow: 'none',
        },
        ':disabled': {
          backgroundColor: '#FFE1E1',
          color: '#FFB2B2',
        },
        ':hover': {
          backgroundColor: '#FF3D3D',
          borderColor: '#FF3D3D',
          boxShadow: 'none',
        },
        border: '4px solid #FF0000',
        padding: '6px 18px',
      },
      outlined: {
        ':active': {
          border: '2px solid #FFB2B2',
          boxShadow: 'none',
        },
        ':hover': {
          backgroundColor: '#FF3D3D',
          border: '2px solid #FFB2B2',
          boxShadow: 'none',
          color: '#FFFFFF',
        },
        backgroundColor: 'transparent',
        border: '2px solid #FF0000',
        color: '#3A3A3A',
        padding: '8px 18px',
      },
      root: {
        backgroundColor: '#FF0000',
        borderRadius: '100px',
        boxShadow: 'none',
        boxSizing: 'border-box',
        color: '#FFFFFF',
        gap: '10px',
        padding: '10px 18px',
      },
      sizeSmall: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '19px',
        maxHeight: '34px',
        padding: '8px 12px',
      },
      text: {
        ':hover': { backgroundColor: ' #FFE1E1', boxShadow: 'none' },
        backgroundColor: 'transparent',
        color: '#3A3A3A',
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
}
