import { Stack, styled, Typography } from '@mui/material'

export const Container = styled(Stack, { shouldForwardProp: (prop) => prop !== 'backgroundImageSrc' })<{
  backgroundImageSrc?: string
}>(({ backgroundImageSrc }) => ({
  backgroundImage: `url(${backgroundImageSrc})`,
  backgroundPosition: 'bottom',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}))

export const BlockTitle = styled(Typography)(({ theme }) => ({
  '& > strong': {
    color: theme.palette.tauroRed.main,
  },
  textAlign: 'center',
}))

export const Content = styled(Stack)(({ theme }) => ({
  '& br': { content: '""', display: 'block', margin: '24px 0 0 0' },
  padding: '96px 100px 120px',

  [theme.breakpoints.down('md')]: {
    padding: '40px 16px',
  },
}))
