import React, { ReactElement, ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

import { Stack } from '@mui/material'

import { FEATURE_FLAGS } from '../../../../shared/constants/feature-flags'
import { FaqQuestionFormBlock } from './FaqQuestionFormBlock'

type BaseFaqLayoutProps = {
  children?: ReactNode
}

export default function BaseFaqLayout(props: BaseFaqLayoutProps): ReactElement {
  const { children = <Outlet /> } = props
  return (
    <Stack width="100%">
      {children}
      {FEATURE_FLAGS.feedbackFormEnable ? <FaqQuestionFormBlock /> : null}
    </Stack>
  )
}
