import React, { ReactNode } from 'react'

import { MenuItem } from '@mui/material'

import { SelectItem } from '.'

export function getTopicItems<T extends string | number>(items: SelectItem<T>[]): ReactNode[] {
  return items.map(({ label, value }, index) => (
    <MenuItem key={index} value={value}>
      {label}
    </MenuItem>
  ))
}
