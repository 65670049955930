import React, { ReactElement } from 'react'

import { StackProps, Stack } from '@mui/material'

import { TauroSpinner } from '../TauroSpinner'

type SceneSpinnerProps = StackProps

export function SceneSpinner(props: SceneSpinnerProps): ReactElement {
  const { height = '100vh', ...rest } = props
  return (
    <Stack alignItems="center" height={height} justifyContent="center" spacing={2} width="100%" {...rest}>
      <TauroSpinner />
    </Stack>
  )
}
