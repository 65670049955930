import { useIsMobile } from './is-mobile'

type Variants = 'desktop' | 'mobile'

type UseBackgroundImage = Record<Variants, string>

export function useBackgroundImage(variants: UseBackgroundImage): string {
  const isMobile = useIsMobile()

  if (isMobile) {
    return variants.mobile
  }

  return variants.desktop
}
