import { RegisterOptions } from 'react-hook-form'

import isEmail from 'validator/es/lib/isEmail'

import { getRequiredFieldRule } from './required'

export const emailValidator = (value: string): string | undefined => {
  return !isEmail(value) ? 'Invalid email format' : undefined
}

export const getEmailFieldRules = (): Pick<RegisterOptions, 'required' | 'validate'> => ({
  ...getRequiredFieldRule(),
  validate: { email: emailValidator },
})
