import { useCallback, useState } from 'react'

type UseToggleReturnType = [boolean, (newValue?: boolean | unknown) => void]

export function useToggle(initialValue = false): UseToggleReturnType {
  const [toggle, setToggle] = useState(initialValue)

  const toggleHandler = useCallback(
    (newValue?: boolean | unknown): void => {
      setToggle((prev) => (typeof newValue === 'boolean' ? newValue : !prev))
    },
    [setToggle],
  )

  return [toggle, toggleHandler]
}
