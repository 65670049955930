import { styled, Typography } from '@mui/material'

export const Link = styled('a')(({ theme }) => ({
  color: theme.typography.body1.color,
  textDecoration: 'underline',
}))

export const TextTitle = styled(Typography)(({ theme }) => ({
  color: '#344054',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '24px',
  margin: theme.spacing(0, 0, 1.5),
}))
