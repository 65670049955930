import { Theme } from '@mui/material'
import { TypographyOptions } from '@mui/material/styles/createTypography'

export function typographyOverride(theme: Theme): TypographyOptions {
  return {
    body1: {
      color: '#344054',
      fontFamily: 'Satoshi',
      fontSize: '18px',
      fontWeight: 400,
      letterSpacing: '0.009375em',
      lineHeight: '28px',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
    body2: {
      color: '#344054',
      fontFamily: 'Satoshi',
      fontSize: '16px',
      fontWeight: 400,
      letterSpacing: '0.01071em',
      lineHeight: '24px',
      [theme.breakpoints.down('md')]: {
        fontSize: '12px',
        lineHeight: '18px',
      },
    },
    button: {
      borderRadius: '50%',
      color: '#FFFFFF',
      fontFamily: 'Satoshi',
      fontSize: '16px',
      fontWeight: 700,
      letterSpacing: '0.03em',
      lineHeight: '22px',
      textTransform: 'none',
    },
    fontFamily: 'Satoshi',
    fontSize: 16,
    fontWeightBold: 700,
    fontWeightLight: 300,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    h1: {
      color: '#3A3A3A',
      fontFamily: 'Satoshi',
      fontSize: '52px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '60px',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px',
        lineHeight: '34px',
      },
    },
    h2: {
      color: '#3A3A3A',
      fontFamily: 'Satoshi',
      fontSize: '40px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '48px',
      [theme.breakpoints.down('md')]: {
        fontSize: '20px',
        lineHeight: '26px',
      },
    },
    h3: {
      color: '#3A3A3A',
      fontFamily: 'Satoshi',
      fontSize: '30px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '36px',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px',
        lineHeight: '24px',
      },
    },
    h4: {
      color: '#3A3A3A',
      fontFamily: 'Satoshi',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '30px',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
        lineHeight: '20px',
      },
    },
    h5: {
      color: '#3A3A3A',
      fontFamily: 'Satoshi',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '24px',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
        lineHeight: '18px',
      },
    },
    h6: {
      color: '#3A3A3A',
      fontFamily: 'Satoshi',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '24px',
      [theme.breakpoints.down('md')]: {
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
    htmlFontSize: 16,
  }
}
