import { Box, keyframes } from '@mui/material'
import { styled } from '@mui/system'

const keyframe = keyframes({
  from: {
    color: '#FF0000',
  },
  to: {
    color: 'transparent',
  },
})

export const IconBox = styled(Box)({
  animation: `${keyframe} 1s infinite alternate`,
}) as typeof Box
