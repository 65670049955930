import { styled, Stack } from '@mui/material'

export const Paper = styled(Stack)(({ theme }) => ({
  alignItems: 'left',
  background: 'rgba(255, 255, 255, 0.80)',
  borderRadius: '16px',
  display: 'flex',
  flex: '1 1 0',
  flexDirection: 'column',
  gap: theme.spacing(2.25),

  [theme.breakpoints.down('sm')]: {
    padding: '20px',
  },

  [theme.breakpoints.up('md')]: {
    padding: '40px',
  },

  width: '100%',
}))
