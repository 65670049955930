import React, { ForwardedRef, forwardRef, ReactElement, ReactNode } from 'react'

import { FormControl, InputLabel, Select as MuiSelect, SelectProps as MuiSelectProps } from '@mui/material'

import { getTopicItems } from './utils'

export type SelectItem<T> = {
  label: ReactNode
  value: T
}

export type SelectProps<T extends string | number = string> = {
  className?: string
  items: SelectItem<T>[]
} & MuiSelectProps<T>

function SelectBase<T extends string | number = string>(
  props: SelectProps<T>,
  ref: ForwardedRef<HTMLSelectElement>,
): ReactElement {
  const { className, items, label, ...rest } = props

  return (
    <FormControl className={className}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect<T> ref={ref} {...rest}>
        {getTopicItems<T>(items)}
      </MuiSelect>
    </FormControl>
  )
}

const Select = forwardRef(SelectBase)

export { Select }
