import { Components, Theme } from '@mui/material'

export function textfieldOverride(
  theme: Theme,
): Pick<
  Components,
  | 'MuiTextField'
  | 'MuiOutlinedInput'
  | 'MuiFormControl'
  | 'MuiFormLabel'
  | 'MuiInputLabel'
  | 'MuiFilledInput'
  | 'MuiInput'
> {
  return {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          ':after': {
            borderColor: theme.palette.tauroRed.main,
          },
          ':before': {
            content: 'none',
          },

          backgroundColor: 'rgba(246, 246, 246, 0.90)',
          borderRadius: theme.shape.borderRadius,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
          boxShadow: 'none',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        filled: {},
        root: {
          '&.Mui-focused': {
            color: theme.typography.body1.color,
          },

          padding: theme.spacing(0, 2.5),
          top: 'initial',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            border: 'none',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          borderRadius: theme.shape.borderRadius,
          boxShadow: 'none',
        },
      },
    },
  }
}
