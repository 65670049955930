import { Components, Theme } from '@mui/material'

export function accordionOverride(
  theme: Theme,
): Pick<Components, 'MuiAccordion' | 'MuiAccordionSummary' | 'MuiAccordionDetails'> {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            display: 'none',
          },

          '&:first-of-type, &:last-of-type': {
            borderRadius: '8px',
          },

          '&:not(:last-child)': {
            marginBottom: theme.spacing(2),
          },
          borderRadius: '8px',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          '& br': { content: '""', display: 'block', margin: '24px 0 0 0' },
          padding: theme.spacing(0, 3.75, 3.75),

          [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0, 3.5, 3.5),
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '& .MuiAccordionSummary-content': {
            '& br': { content: '""', display: 'block', margin: '24px 0 0 0' },
            margin: 0,
            padding: theme.spacing(3.75),

            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(3.5),
            },
          },
          padding: theme.spacing(0, 3.75, 0, 0),

          [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0, 3.5, 0, 0),
          },
        },
      },
    },
  }
}
