import { PaletteOptions } from '@mui/material'

declare module '@mui/material/styles' {
  interface Palette {
    tauroRed: Palette['primary']
  }

  interface PaletteOptions {
    tauroRed?: PaletteOptions['primary']
  }
}

export const palette: PaletteOptions = {
  error: {
    main: '#FF0000',
  },
  tauroRed: {
    main: '#FF0000',
  },
}
