import React, { FunctionComponent, ReactElement } from 'react'

import { Trans } from '@lingui/macro'
import { Box, Link, Stack } from '@mui/material'

import { ReactComponent as Android } from './assets/download_android.svg'
import { ReactComponent as Apple } from './assets/download_apple.svg'
import { ReactComponent as Windows } from './assets/download_windows.svg'
import { Text } from './styles'

type LogoProps = {
  icon: FunctionComponent<React.SVGProps<SVGSVGElement>>
  href: string
}

const WINDOWS_MT4_DOWNLOAD_LINK = 'https://download.mql5.com/cdn/web/13193/mt4/jmfinancial24setup.exe'
const ANDROID_MT4_DOWNLOAD_LINK =
  'https://play.google.com/store/apps/details?id=net.metaquotes.metatrader4&hl=en&referrer=ref_id%253d5188595229970786286%2526utm_source%253dwww.metatrader4.com%2526utm_campaign%253ddownload'
const APPLE_MT4_DOWNLOAD_LINK = 'https://apps.apple.com/kw/app/metatrader-4/id496212596'

export function MT4Platform(): ReactElement {
  return (
    <Stack direction="column" gap={0.75}>
      <Text>
        <Trans>Download MT4 Platform</Trans>
      </Text>
      <Stack direction="row" gap={1.25}>
        <Logo href={WINDOWS_MT4_DOWNLOAD_LINK} icon={Windows} />
        <Logo href={APPLE_MT4_DOWNLOAD_LINK} icon={Apple} />
        <Logo href={ANDROID_MT4_DOWNLOAD_LINK} icon={Android} />
      </Stack>
    </Stack>
  )
}

const Logo = ({ href, icon }: LogoProps): ReactElement => (
  <Link href={href}>
    <Box component={icon} />
  </Link>
)
