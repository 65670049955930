import { createContext } from 'react'

import { Locale } from '../../shared/i18n'

export type I18nContextType = {
  setLocale: (locale: Locale) => void
  initLocale: Locale
}

export const I18nContext = createContext<I18nContextType | null>(null)
