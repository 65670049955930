import React, { ReactElement, ReactNode } from 'react'

import { SnackbarProvider as SnackbarProviderBase } from 'notistack'

type SnackbarProviderProps = {
  children: ReactNode
}
export function SnackbarProvider({ children }: SnackbarProviderProps): ReactElement {
  return <SnackbarProviderBase>{children}</SnackbarProviderBase>
}
