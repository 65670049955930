import React, { ReactElement } from 'react'

import { Trans } from '@lingui/macro'
import { Button, Stack, Typography } from '@mui/material'

import { useAcknowledge } from './hooks/use-acknowledge'
import { Content, Dialog } from './styles'
import { getConsumerCountryWarningText } from './utils'

export function GeoSpecificModal(): ReactElement {
  const [isAcknowledged, countryCode, leave, acknowledge] = useAcknowledge()

  const handleAcknowledge = (): void => acknowledge()

  const handleLeaveWebsite = (): void => leave()

  return (
    <Dialog maxWidth="md" open={!isAcknowledged}>
      <Content>
        <Typography textAlign="left">{getConsumerCountryWarningText(countryCode)}</Typography>
        <Stack alignItems={'center'} direction={{ md: 'row', xs: 'column' }} gap={{ md: 4, xs: 2 }} width="100">
          <Button
            sx={(theme) => ({ color: theme.palette.tauroRed.main })}
            variant="outlined"
            fullWidth
            onClick={handleLeaveWebsite}
          >
            <Trans>Leave Website</Trans>
          </Button>
          <Button variant="contained" fullWidth onClick={handleAcknowledge}>
            <Trans>Acknowledge</Trans>
          </Button>
        </Stack>
      </Content>
    </Dialog>
  )
}
