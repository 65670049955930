import React, { ReactElement, ReactNode, useCallback, useState } from 'react'

import { LiveChatWidget, WidgetConfig } from '@livechat/widget-react'

import { config } from '../../config'
import { LiveChatContext } from './context'

type LiveChatProviderProps = { children: ReactNode }

export function LiveChatProvider(props: LiveChatProviderProps): ReactElement {
  const { children } = props

  const [chatWidgetVisibility, setChatWidgetVisibility] = useState<WidgetConfig['visibility']>('minimized')

  const toggleChatWidgetVisibility = useCallback((): void => {
    setChatWidgetVisibility((chatWidgetVisibilityState) =>
      chatWidgetVisibilityState === 'minimized' ? 'maximized' : 'minimized',
    )
  }, [setChatWidgetVisibility])

  return (
    <>
      <LiveChatContext.Provider value={{ toggleChatWidgetVisibility }}>{children}</LiveChatContext.Provider>
      <LiveChatWidget license={config.liveChatLicenseKey ?? ''} visibility={chatWidgetVisibility} />
    </>
  )
}
