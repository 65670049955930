import { Components, Theme } from '@mui/material'

export function dialogOverride(theme: Theme): Pick<Components, 'MuiDialog'> {
  return {
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': {
            borderRadius: theme.spacing(2),
            boxShadow: 'none',
          },

          '& .MuiModal-backdrop': {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        },
      },
    },
  }
}
