import { styled, Dialog as MuiDialog, Stack } from '@mui/material'

export const Dialog = styled(MuiDialog)({
  boxShadow: 'none',
})

export const Content = styled(Stack)(({ theme }) => ({
  '& br': { content: '""', display: 'block', margin: '24px 0 0 0' },

  gap: theme.spacing(4),
  padding: theme.spacing(5),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3),
  },
}))
