import React, { ReactElement, ReactNode } from 'react'

import { i18n } from '@lingui/core'
import { I18nProvider as I18nProviderBase } from '@lingui/react'

import { activateLocale, getInitialLocale } from '../../shared/i18n'
import { I18nContext } from './context'

type I18nProviderProps = {
  children: ReactNode
}

const locale = getInitialLocale()
activateLocale(locale)

export function I18nProvider(props: I18nProviderProps): ReactElement {
  const { children } = props

  const initLocale = getInitialLocale()

  return (
    <I18nProviderBase forceRenderOnLocaleChange={false} i18n={i18n}>
      <I18nContext.Provider value={{ initLocale, setLocale: activateLocale }}>{children}</I18nContext.Provider>
    </I18nProviderBase>
  )
}
