import React, { ReactElement } from 'react'

import { Trans } from '@lingui/macro'
import { Stack } from '@mui/material'

import { Text } from '../../styled'

export function Copyright(): ReactElement {
  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between" py="8px" width="100%">
      <Text textAlign="center" width="100%">
        <Trans>Copyright © 2024. Tauro Markets. All Rights Reserved.</Trans>
      </Text>
    </Stack>
  )
}
