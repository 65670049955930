import { useContext } from 'react'

import { I18nContext, I18nContextType } from '../context'

type UseSetLocaleReturnedType = Pick<I18nContextType, 'setLocale' | 'initLocale'>

export function useSetLocale(): UseSetLocaleReturnedType {
  const invariant = useContext(I18nContext)

  if (!invariant) throw new Error('I18nContext is null')

  return invariant
}
