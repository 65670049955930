import { i18n, LocaleData, Messages } from '@lingui/core'
import invariant from 'tiny-invariant'

import { localeData as aeLocaleData } from '../locales/ar'
import { messages as aeMessages } from '../locales/ar/messages'
import { localeData as enLocaleData } from '../locales/en'
import { messages as enMessages } from '../locales/en/messages'

const LOCALE_STORAGE_KEY = 'locale'

export type Locale = keyof typeof locales

export type Translate = { messages: Messages; localeData: LocaleData }

export const locales = {
  ar: 'Arabic',
  en: 'English',
}
export const defaultLocale: Locale = 'en'

export const translates: Record<Locale, Translate> = {
  ar: {
    localeData: aeLocaleData,
    messages: aeMessages,
  },
  en: {
    localeData: enLocaleData,
    messages: enMessages,
  },
}

const AllowedLocales = Object.keys(locales)

const isValidLocale = (value: unknown): value is Locale => AllowedLocales.includes(value as Locale)

export function getInitialLocale(): Locale {
  const localStorageLang = localStorage.getItem(LOCALE_STORAGE_KEY)

  if (localStorageLang != null && isValidLocale(localStorageLang)) return localStorageLang

  return defaultLocale
}

export function activateLocale(locale: Locale): void {
  invariant(isValidLocale(locale))

  localStorage.setItem(LOCALE_STORAGE_KEY, locale)

  const messages = translates[locale].messages
  const localeData = translates[locale].localeData

  i18n.load({ [locale]: messages })
  i18n.loadLocaleData({ [locale]: localeData })
  i18n.activate(locale)
}
