import React, { ForwardedRef, forwardRef, ReactElement, ReactNode } from 'react'

import { Stack, StackProps, Typography } from '@mui/material'

import { BlockTitle, Container, Content } from './styles'

export type BlockLayoutProps = {
  title?: ReactNode
  description?: ReactNode
  children?: ReactNode
  backgroundImageSrc?: string
  className?: string
  descriptionWidth?: StackProps['width']
} & Omit<StackProps, 'title' | 'children'>

function BlockLayoutBase(props: BlockLayoutProps, ref: ForwardedRef<HTMLDivElement>): ReactElement {
  const {
    backgroundImageSrc,
    children,
    className,
    description,
    descriptionWidth = { md: '60%', xs: '100%' },
    title,
    ...rest
  } = props

  const contentGap = title == null && description == null ? 0 : { md: 8, xs: 8 }

  return (
    <Container
      alignItems="center"
      backgroundImageSrc={backgroundImageSrc}
      className={className}
      direction="column"
      width="100%"
      {...rest}
      ref={ref}
    >
      <Stack maxWidth="1440px" width={'100%'}>
        <Content alignItems="center" gap={contentGap} width="100%">
          <Stack alignItems="center" gap={1.625} width={descriptionWidth}>
            {title != null ? (
              <BlockTitle position="relative" textAlign="center" variant="h1">
                {title}
              </BlockTitle>
            ) : null}
            {description != null ? (
              <Typography position="relative" textAlign="center" variant="body1">
                {description}
              </Typography>
            ) : null}
          </Stack>
          {children}
        </Content>
      </Stack>
    </Container>
  )
}

export const BlockLayout = forwardRef(BlockLayoutBase)
