import React from 'react'
import { ReactNode } from 'react'

import { Trans } from '@lingui/macro'
import { Link } from '@mui/material'

import { config } from '../../../../config'

const EU = (
  <Trans>
    The website <Link href={config.urls.dashboardUrl}>{config.urls.frontendUrl}</Link> (the “Website”) is not intended
    for, and addressed to EU residents and is only addressed to persons resident in jurisdictions where it is lawful for
    the Website to be accessible. Tauro Markets and its affiliated entities are not regulated by the EU and are
    therefore not authorized to offer investment services and financial instruments.
    <br />
    The Website does not constitute an offer or a solicitation to subscribe for investment services and/or financial
    instruments of Tauro Markets and/or its affiliated entities.
    <br />
    By clicking the “Acknowledge” button, you confirm that the decision to visit and browse the Website was made
    independently at your own exclusive initiative and that no solicitation or recommendation has been made by Tauro
    Markets or any other affiliated entity.
  </Trans>
)

const CA = (
  <Trans>
    The website <Link href={config.urls.dashboardUrl}>{config.urls.frontendUrl}</Link>(the “Website”) is not intended
    for, and addressed to Canadian residents and is only addressed to persons resident in jurisdictions where it is
    lawful for the Website to be accessible. Tauro Markets and its affiliated entities are not regulated in Canada and
    are therefore not authorized to offer investment services and financial instruments.
    <br />
    The Website does not constitute an offer or a solicitation to subscribe for investment services and/or financial
    instruments of Tauro Markets and/or its affiliated entities.
    <br />
    By clicking the “Acknowledge” button, you confirm that the decision to visit and browse the Website was made
    independently at your own exclusive initiative and that no solicitation or recommendation has been made by Tauro
    Markets or any other affiliated entity.
  </Trans>
)

const AU = (
  <Trans>
    The website <Link href={config.urls.dashboardUrl}>{config.urls.frontendUrl}</Link>(the “Website”) is not intended
    for, and addressed to Australian residents and is only addressed to persons resident in jurisdictions where it is
    lawful for the Website to be accessible. Tauro Markets and its affiliated entities are not regulated in Australia
    and are therefore not authorized to offer investment services and financial instruments.
    <br />
    The Website does not constitute an offer or a solicitation to subscribe for investment services and/or financial
    instruments of Tauro Markets and/or its affiliated entities.
    <br />
    By clicking the “Acknowledge” button, you confirm that the decision to visit and browse the Website was made
    independently at your own exclusive initiative and that no solicitation or recommendation has been made by Tauro
    Markets or any other affiliated entity.
  </Trans>
)

export function getConsumerCountryWarningText(country: string): ReactNode {
  switch (country.toLowerCase()) {
    case 'au':
      return AU
    case 'ca':
      return CA
    default:
      return EU
  }
}
