import { useCallback, useState } from 'react'

import { t } from '@lingui/macro'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { enqueueSnackbar } from 'notistack'

import { useRestClient } from '../../providers/RestClientProvider/hooks/use-rest-client'

type Options = {
  onSuccess?: () => void
  onError?: () => void
  showNotification?: boolean
}
type UsePostParamsType = { path: string }
type UsePostReturnedType<P = unknown, T = unknown> = [
  (payload: P) => void,
  {
    data: T | undefined
    loading: boolean
    error: unknown
  },
]

export function usePost<T = unknown, P = unknown>(
  params: UsePostParamsType,
  opts: Options = {},
): UsePostReturnedType<P, T> {
  const { onError, onSuccess, showNotification } = opts
  const { path } = params

  const { client } = useRestClient()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<T>()
  const [error, setError] = useState<unknown>()

  const fn = useCallback(
    (payload: P, config?: AxiosRequestConfig) => {
      setLoading(true)
      client
        .post<T, AxiosResponse<T>, P>(path, payload, config)
        .then((response) => {
          setLoading(false)
          setData(response.data)
          onSuccess?.()

          if (showNotification) enqueueSnackbar({ message: t`Success`, variant: 'success' })
        })
        .catch((error) => {
          setError(error)
          setLoading(false)
          onError?.()

          if (showNotification) enqueueSnackbar({ message: t`Something went wrong please try again`, variant: 'error' })
        })
        .finally(() => setLoading(false))
    },
    [client, params, opts, setLoading, setData, setLoading],
  )

  return [fn, { data, error, loading }]
}
