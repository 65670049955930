import { useCallback } from 'react'

import { createClient, RestClientInstance } from '../../../shared/create-client'

export function useCreateClient(): RestClientInstance {
  const client = useCallback(() => {
    return createClient()
  }, [])
  return client()
}
