import { Stack, Icon as MuiIcon, styled } from '@mui/material'

export const NavigationContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  border: '1px solid #d9d9d9',
  borderWidth: '0 0 1px 0',
  padding: '10px 100px 12px 100px',

  [theme.breakpoints.down('lg')]: {
    padding: '10px 20px 12px 20px',
  },

  [theme.breakpoints.down('md')]: {
    padding: '10px 16px 12px 16px',
  },
}))

export const DrawerHeaderContainer = styled(Stack)({
  border: '1px solid #d9d9d9',
  borderWidth: '0 0 1px 0',
  padding: '10px 16px 12px 16px',
  transition: 'all 0.4s ease-in-out',
  width: '100%',
})

export const Icon = styled(MuiIcon)(({ theme }) => ({
  '& > svg': {
    color: theme.palette.tauroRed.main,
  },
}))
