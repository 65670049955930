import React, { ReactElement } from 'react'

import { Divider, Stack } from '@mui/material'

import { useIsMobile } from '../../../../../../shared/hooks/is-mobile'
import { useDir } from '../../../../../../shared/hooks/use-dir'
import { NavItem } from '../Navtem'
import { NAV_ITEMS } from './constants'

type NavigationProps = {
  onClick?: () => void
}

export function Navigation(props: NavigationProps): ReactElement {
  const { onClick } = props

  const isMobile = useIsMobile()
  const dir = useDir()

  const handleClick = (): void => {
    onClick?.()
  }

  return (
    <Stack dir={dir} direction={{ md: 'row', xs: 'column' }} gap={{ xs: 1 }} width={{ md: 'auto', xs: '100%' }}>
      {NAV_ITEMS.map((item) => (
        <div key={item.id}>
          <NavItem key={item.id} {...item} onClick={handleClick} />
          {isMobile ? <Divider /> : null}
        </div>
      ))}
    </Stack>
  )
}
