import React from 'react'

import ReactDOM from 'react-dom/client'

import { App } from './app'

const $root = document.getElementById('#root')

if ($root == null) throw new Error('Root element not found')

const root = ReactDOM.createRoot($root)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
