import { ReactNode } from 'react'

import { RoutesType } from './types/routes'

type RenderRouteInputType = {
  element?: ReactNode
  path: RoutesType
  crumb?: { crumbComponent?: ReactNode; isClickable?: boolean }
  param?: string
}

export type BreadcrumbType = { path: string; crumbComponent?: ReactNode; isClickable?: boolean }

export type RenderRoutePropsReturnedType = {
  path: string
  handle: { crumb?: BreadcrumbType }
  element?: ReactNode
}

export function renderRouteProps(input: RenderRouteInputType): RenderRoutePropsReturnedType {
  const { crumb = {}, param, path, ...rest } = input
  const { crumbComponent, isClickable = true } = crumb

  const returnedPath = param ? `${path}/:${param}` : path

  return { handle: { crumb: { crumbComponent, isClickable, path } }, path: returnedPath, ...rest }
}
