import axios, { AxiosInstance, CreateAxiosDefaults } from 'axios'

import { config } from '../config'

const CLIENT_TIMEOUT = 5000

const apiURL = `${config.urls.backendBaseURL}/api/`

export type RestClientInstance = AxiosInstance

export function createClient(opts?: CreateAxiosDefaults): RestClientInstance {
  const client = axios.create({
    baseURL: apiURL,
    timeout: CLIENT_TIMEOUT,
    ...opts,
  })

  return client
}
