import { useCallback, useState } from 'react'

type UseConsentBannerStateReturnedType = [boolean, () => void]

export function useConsentBannerState(): UseConsentBannerStateReturnedType {
  const [isConsented, setIsConsented] = useState<boolean>(false)

  const handleSetIsConsented = useCallback(() => {
    setIsConsented(true)
  }, [setIsConsented])

  return [isConsented, handleSetIsConsented]
}
