const COUNTRIES_ALPHA2_NEED_TO_ACKNOWLEDGE: string[] = [
  'at',
  'au',
  'be',
  'bg',
  'ca',
  'cy',
  'cz',
  'de',
  'dk',
  'ee',
  'es',
  'fi',
  'fr',
  'gr',
  'hr',
  'hu',
  'ie',
  'it',
  'lt',
  'lu',
  'lv',
  'mt',
  'nl',
  'pl',
  'pt',
  'ro',
  'se',
  'si',
  'sk',
]

export const COUNTRIES_ALPHA2_NEED_TO_ACKNOWLEDGE_SET = new Set(
  COUNTRIES_ALPHA2_NEED_TO_ACKNOWLEDGE.map((c) => c.toLowerCase()),
)
