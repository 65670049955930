import { NavLink } from 'react-router-dom'

import { Button as ButtonBase, styled, Icon as MuiIcon } from '@mui/material'

export const Link = styled(NavLink)({
  textDecoration: 'none',
})

export const Button = styled(ButtonBase, { shouldForwardProp: (prop) => prop !== 'isActive' })<{ isActive?: boolean }>(
  ({ isActive, theme }) => ({
    color: isActive ? theme.palette.tauroRed.main : theme.palette.text.primary,
    minWidth: 0,
  }),
)

export const CollapseButton = styled(ButtonBase)({
  ':active, :hover': { backgroundColor: 'transparent' },
  color: '#3A3A3A',
  fontSize: '14px',
  fontWeight: 500,
  gap: 0,
  height: '18px',
  lineHeight: '18px',
  margin: '8px 0 8px 12px',
  padding: '0',
  textTransform: 'none',
  width: 'max-content',
})

export const Icon = styled(MuiIcon)({
  '& > svg': { color: '#3A3A3A', width: '20px' },
})
