import { useLingui } from '@lingui/react'

export type DirectionType = 'rtl' | 'ltr'

export function useDir(): DirectionType {
  const {
    i18n: { locale },
  } = useLingui()

  return locale === 'ar' ? 'rtl' : 'ltr'
}
